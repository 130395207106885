import React, { Component, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link, navigate, StaticQuery, graphql } from 'gatsby';
import Helmet from 'react-helmet';
import Swipeable from 'react-swipeable';
import Transition from '../components/transition';
import { StaticImage } from 'gatsby-plugin-image';
import favicon from '../favicon.png';
import CoeticGT from "../images/coetic-gt.svg"
import './index.css';

function ScrollToTop() {
  const { pathname } = window.location.pathname;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}


// const Team = () => {

//   return (
//     <div className="team-slider">
//       <div className="team-member">
//         <StaticImage src="../team/arbershabani.jpeg" alt="Arber Shabani" />
//         <span className="sub-title name">Arbër Shabani</span>
//         <span className="sub-title role">Chief Executive Officer</span>
//       </div>
//       <div className="team-member">
//         <StaticImage src="../team/arbershabani.jpeg" alt="AbdylKadri Maksuti" />
//         <span className="sub-title name">Abdylkadri Maksuti</span>
//         <span className="sub-title role">React Native Engineer</span>
//       </div>
//       <div className="team-member">
//         <StaticImage src="../team/arbershabani.jpeg" alt="AbdylKadri Maksuti" />
//         <span className="sub-title name">Edona Rexhepi</span>
//         <span className="sub-title role">Chief Marketing Officer</span>
//       </div>
//     </div>
//   );
// };

const Header = props => {
  const { name, title, date, toggleNavbar, closeNavbar, navbar, pathName } = props;
  const updateSize = event => {
    if (window.innerWidth > 781 && { navbar: true }) {
      closeNavbar();
    }
  };

  useEffect(() => {
    window.addEventListener('resize', updateSize);
    return () => {
      window.removeEventListener('resize', updateSize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fluidPage = pathName?.includes('contact');
  console.log(props, pathName);

  return (
    <header class={navbar ? 'header2 header2-height' : 'header2'} style={fluidPage ? { position: 'relative', border: 0 } : {}}>
      <Link to="/" onClick={() => {
          closeNavbar();
          ScrollToTop();
        }}
      >
        <div className="left-logo-2" />
      </Link>

      <div className="hamburger-container">
        {navbar ? (
          // eslint-disable-next-line
          <div className="close-button" onClick={toggleNavbar} />
        ) : (
          // eslint-disable-next-line
          <div class="burger-button2" onClick={toggleNavbar}>
            <div class="" />
            <div class="line" />
            <div class="" />
          </div>
        )}
      </div>

      <div class={navbar ? 'main-nav-res' : 'main-nav'}>
        <div class={navbar ? 'main-nav-list-res' : 'main-nav-list'}>
          <Link
            to="/"
            className="main-nav-link"
            activeClassName="main-nav-link underline-text"
            onClick={() => {
              closeNavbar();
              ScrollToTop();
            }}
          >
            HOME
          </Link>
          <Link
            to="https://blog.coetic-x.com/"
            className="main-nav-link"
            activeClassName="main-nav-link underline-text"
            onClick={() => {
              closeNavbar();
              ScrollToTop();
            }}
          >
            BLOG
          </Link>
          <Link
            to="/about-us/"
            className="main-nav-link"
            activeClassName="main-nav-link underline-text"
            onClick={() => {
              closeNavbar();
              ScrollToTop();
            }}
          >
            ABOUT US
          </Link>

          <Link
            to="/contact/"
            className="main-nav-link"
            activeClassName="main-nav-link underline-text"
            onClick={() => {
              closeNavbar();
              ScrollToTop();
            }}
          >
            CONTACT
          </Link>
         
           {/* <Link
            to="https://coetic-gt.com/"
            className="main-nav-link gt"
            activeClassName="main-nav-link underline-text"
            // className="main-nav-link hire-dev space-top"
            onClick={() => {
              closeNavbar();
              ScrollToTop();
            }}
          >
            
            <img src={" https://coetic-gt.com/assets/logo-341969be.svg"} style={{filter: 'drop-shadow(2px 4px 6px rgba(0,0,0,0.5))',width: 26, marginBottom:4}}/>
            
            
          </Link> */}
          {/* <Link
            to="https://coetic-gt.com/"
            className="main-nav-link gt"
            activeClassName="main-nav-link underline-text"
            // className="main-nav-link hire-dev space-top"
            onClick={() => {
              closeNavbar();
              ScrollToTop();
            }}
          >
            /* <img src={CoeticGT} style={{filter: 'brightness(0.1) invert(1)',width: 86, marginBottom:4}}/> 
            <img src={CoeticGT} style={{filter: 'drop-shadow(2px 4px 6px rgba(0,0,0,0.5))',width: 86, marginBottom:4}}/>
            
            /* filter: brightness(0.1) invert(1) 
          </Link> */}
          {/* works */}
          {/* <Link
            to="/works"
            className="main-nav-link"
            activeClassName="main-nav-link underline-text"
            onClick={() => {
              closeNavbar();
              ScrollToTop();
            }}
          >
            WORKS
          </Link> */}
          {/* prices */}
          {/* <Link
            to="/prices"
            className="main-nav-link"
            activeClassName="main-nav-link underline-text"
            onClick={() => {
              closeNavbar();
              ScrollToTop();
            }}
          >
            WORKS
          </Link> */}
          {/* <Link
            to="/our-services"
            className="main-nav-link"
            activeClassName="main-nav-link underline-text"
            onClick={() => {
              closeNavbar();
              ScrollToTop();
            }}
          >
            SERVICES
          </Link> */}
          {/* <Link
            to="/hire-a-dev"
            className="main-nav-link hire-dev space-top"
            onClick={() => {
              closeNavbar();
              ScrollToTop();
            }}
          >
            HIRE A DEVELOPER
          </Link> */}
        </div>
      </div>
    </header>
  );
};

const SocialLinks = () => (
  <>
    <a href="https://linkedin.com/company/coeticx" target='_blank'>
      <svg xmlns="http://www.w3.org/2000/svg" width="46" height="46" viewBox="0 0 46 46">
        <g id="Group_25" data-name="Group 25" transform="translate(-1287 -3850)">
          <g id="Ellipse_1" data-name="Ellipse 1" transform="translate(1287 3850)" fill="none" stroke="#ffffff4d" stroke-width="1">
            <circle cx="23" cy="23" r="23" stroke="none" />
            <circle cx="23" cy="23" r="22.5" fill="none" />
          </g>
          <g id="Group_14" data-name="Group 14" transform="translate(1301.43 3863.661)">
            <path
              id="Path_12"
              data-name="Path 12"
              d="M668.587,249.833a1.851,1.851,0,0,1-2,1.856,1.86,1.86,0,1,1,2-1.856ZM664.752,265.1V253.155h3.713V265.1Z"
              transform="translate(-664.654 -247.976)"
              fill="#ebebeb"
            />
            <path
              id="Path_13"
              data-name="Path 13"
              d="M701.728,282.644c0-1.49-.049-2.736-.1-3.811h3.224l.171,1.661h.073a4.29,4.29,0,0,1,3.689-1.93c2.443,0,4.275,1.637,4.275,5.155v7.06h-3.713v-6.62c0-1.539-.538-2.589-1.881-2.589a2.039,2.039,0,0,0-1.905,1.392,2.672,2.672,0,0,0-.122.928v6.889h-3.713Z"
              transform="translate(-695.695 -273.654)"
              fill="#ebebeb"
            />
          </g>
        </g>
      </svg>
    </a>

    <a href="https://instagram.com/coeticx" target='_blank'>
      <svg xmlns="http://www.w3.org/2000/svg" width="46" height="46" viewBox="0 0 46 46">
        <g id="Group_26" data-name="Group 26" transform="translate(-1350 -3850)">
          <g id="Ellipse_1" data-name="Ellipse 1" transform="translate(1350 3850)" fill="none" stroke="#ffffff4d" stroke-width="1">
            <circle cx="23" cy="23" r="23" stroke="none" />
            <circle cx="23" cy="23" r="22.5" fill="none" />
          </g>
          <g id="Instagram_Icon_Footer" transform="translate(1364.621 3863.126)">
            <path
              id="Shape"
              d="M18.5,5.872a7.789,7.789,0,0,0-.431-2.422A4.924,4.924,0,0,0,17,1.68,4.548,4.548,0,0,0,15.354.527,6.377,6.377,0,0,0,13.1.062C12.108.012,11.8,0,9.277,0S6.443.012,5.454.059A6.361,6.361,0,0,0,3.2.523,4.51,4.51,0,0,0,1.558,1.678,4.969,4.969,0,0,0,.489,3.445,7.81,7.81,0,0,0,.058,5.867C.011,6.938,0,7.277,0,9.988S.011,13.039.054,14.1a7.789,7.789,0,0,0,.431,2.422A4.976,4.976,0,0,0,1.558,18.3,4.548,4.548,0,0,0,3.2,19.453a6.377,6.377,0,0,0,2.25.464c.989.047,1.3.059,3.823.059s2.834-.011,3.823-.059a6.357,6.357,0,0,0,2.25-.464,4.942,4.942,0,0,0,2.715-2.922,7.816,7.816,0,0,0,.431-2.422c.044-1.065.054-1.4.054-4.117S18.543,6.938,18.5,5.872Zm-1.67,8.153a5.842,5.842,0,0,1-.323,1.857,3.2,3.2,0,0,1-1.761,1.9,4.787,4.787,0,0,1-1.721.343c-.978.047-1.272.059-3.747.059s-2.772-.011-3.747-.059a4.768,4.768,0,0,1-1.721-.343,2.843,2.843,0,0,1-1.065-.746,3.138,3.138,0,0,1-.693-1.147,5.864,5.864,0,0,1-.319-1.857c-.044-1.053-.054-1.372-.054-4.036s.01-2.987.054-4.036A5.839,5.839,0,0,1,2.052,4.1a3.067,3.067,0,0,1,.7-1.147,2.868,2.868,0,0,1,1.065-.745,4.791,4.791,0,0,1,1.724-.335c.978-.047,1.272-.059,3.747-.059s2.772.012,3.747.059a4.771,4.771,0,0,1,1.721.343,2.841,2.841,0,0,1,1.065.745,3.138,3.138,0,0,1,.693,1.147,5.867,5.867,0,0,1,.323,1.857c.044,1.053.054,1.372.054,4.036s-.017,2.973-.058,4.026Z"
              transform="translate(0)"
              fill="#ebebeb"
            />
            <path
              id="Shape-2"
              data-name="Shape"
              d="M7.564,4a4.564,4.564,0,1,0,4.564,4.564A4.564,4.564,0,0,0,7.564,4Zm0,7.525a2.961,2.961,0,1,1,2.961-2.961A2.961,2.961,0,0,1,7.564,11.525Z"
              transform="translate(1.709 2.135)"
              fill="#ebebeb"
            />
            <path id="Path" d="M12.176,4.589A1.589,1.589,0,1,1,10.588,3a1.589,1.589,0,0,1,1.588,1.589Z" transform="translate(3.516 0.957)" fill="#ebebeb" />
          </g>
        </g>
      </svg>
    </a>

    <a href="https://facebook.com/coeticx" target='_blank'>
      <svg xmlns="http://www.w3.org/2000/svg" width="46" height="46" viewBox="0 0 46 46">
        <g id="Group_147" data-name="Group 147" transform="translate(-1467 -3839)">
          <g id="Ellipse_1" data-name="Ellipse 1" transform="translate(1467 3839)" fill="none" stroke="#ffffff4d" stroke-width="1">
            <circle cx="23" cy="23" r="23" stroke="none" />
            <circle cx="23" cy="23" r="22.5" fill="none" />
          </g>
          <g id="facebook" transform="translate(1208.596 3513.78)">
            <path
              id="Path_42"
              data-name="Path 42"
              d="M283.885,341.915h2.624V338.04h-3.086v.014c-3.737.133-4.5,2.233-4.571,4.44h-.007v1.936H276.3v3.8h2.546V358.4h3.835V348.226h3.143l.606-3.8h-3.748v-1.169a1.25,1.25,0,0,1,1.2-1.345Zm0,0"
              fill="#fff"
            />
          </g>
        </g>
      </svg>
    </a>
  </>
);

const Footer = ({ fluidPage }) => {
  return (
    <footer className="footer">
      <div className="footer-new-bottom-container">
        <div className="footer-new-bottom-left-container">
          <Link to="/#" onClick="ScrollToTop">
            <div className="coetic-logo"></div>
          </Link>

          <div className="seperator-line" />
          <p className="where-code-ethic-text">Where the code ethic prospers!</p>
        </div>
        <div className="footer-new-bottom-right-container">
          <ul>
            <li>
              <a href="javascript:void(0);">Copyright {new Date().getFullYear()}</a>
            </li>
            <span className="list-seperator" />
            <li>
              <a href="javascript:void(0);">Privacy Policy</a>
            </li>
            <span className="list-seperator" />
            <li>
              <a href="javascript:void(0);">Website Terms</a>
            </li>
          </ul>
        </div>
      </div>
      {!fluidPage && (
        <div className="footer-container">
          <div className="footer-item-container">
            <p className="footer-any-project-in-mind">Have any project in your mind?</p>
            <a href="/contact" className="footer-collaborate-text">
              Book a free appointment today
            </a>
          </div>

          <div className="footer-item-container">
            <p className="footer-title-text">Company</p>
            <div className="seperator-title" />
            <ul className="company-ul-list">
              <li>
                <a href="//blog.coetic-x.com">Blog</a>
              </li>
              <li>
                <a href="/#our-works">Projects</a>
              </li>
              <li>
                <a href="/contact">Careers</a>
              </li>
              <li>
                <a href="/about-us">About us</a>
              </li>
              <li>
                <a href="/about-us/#faq">FAQ</a>
              </li>
            </ul>
          </div>

          <div className="footer-item-container">
            <p className="footer-title-text">Contact us</p>
            <div className="seperator-title" />
            <ul className="contact-us-ul-list">
              <li>
                <a href="javascript:void(0);">arber@coetic-x.com</a>
              </li>
              <li>
                <a href="javascript:void(0);">+383 45 250 180</a>
              </li>
              <li>
                <a href="javascript:void(0);">Prishtina, Kosovo</a>
              </li>
            </ul>
          </div>

          <div className="footer-item-container">
            <p className="footer-title-text">Social</p>
            <div className="seperator-title" />

            <div className="footer-social-links-container">
              <SocialLinks />
            </div>
          </div>
        </div>
      )}
    </footer>
  );
};

class TemplateWrapper extends Component {
  // NEXT = [13, 32, 39];
  // PREV = 37;

  state = {
    navbar: false,
  };

  closeNavbar = () => {
    this.setState({ navbar: false });
  };

  toggleNavbar = () => {
    this.setState({ navbar: !this.state.navbar });
  };

  swipeLeft = () => {
    // this.navigate({ keyCode: this.NEXT[0] });
  };

  swipeRight = () => {
    // this.navigate({ keyCode: this.PREV });
  };

  navigate = ({ keyCode }) => {
    this.closeNavbar();
    // console.log(this.props);
    // debugger;
    const now = this.props.path;
    // console.log(now);
    if (now) {
      // if (keyCode === this.PREV && now === 1) {
      //   return false;
      // } else if (this.NEXT.indexOf(keyCode) !== -1 && now === slidesLength) {
      //   return false;
      // } else if (this.NEXT.indexOf(keyCode) !== -1) {
      //   navigate(`/${now + 1}`);
      // } else if (keyCode === this.PREV) {
      //   navigate(`/${now - 1}`);
      // }
      // if (keyCode === this.PREV && now === '/') {
      //   return false;
      // } else if (this.NEXT.indexOf(keyCode) !== -1 && now === '/contact') {
      //   return false;
      // } else if (this.NEXT.indexOf(keyCode) !== -1) {
      //   if (now === '/') {
      //     navigate(`/about-us`);
      //   } else if (now === '/about-us') {
      //     navigate(`/faq`);
      //   } else if (now === '/faq') {
      //     navigate(`/jobs`);
      //   } else if (now === '/jobs') {
      //     navigate(`/contact`);
      //   } else if (now === '/services') {
      //     navigate(`/services`);
      //   }
      // } else if (keyCode === this.PREV) {
      //   if (now === '/contact') {
      //     navigate(`/jobs`);
      //   } else if (now === '/jobs') {
      //     navigate(`/faq`);
      //   } else if (now === '/faq') {
      //     navigate(`/about-us`);
      //   } else if (now === '/about-us') {
      //     navigate(`/`);
      //   } else if (now === '/services') {
      //     navigate('/services');
      //   }
      // }
    }
  };

  componentDidMount() {
    document.addEventListener('keydown', this.navigate);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.navigate);
  }

  render() {
    const { location, children, site, pathName } = this.props;

    const fluidPage = location.pathname?.includes('contact') ||  pathName?.includes("contact");
    return (
      <div>
        <Helmet>
          <html lang="en" />
          <title>{site.siteMetadata.title}</title>
          <meta
            name="description"
            content={
              'Coetic X is a software development company interested in scaling up, growing the network, connecting with teams around the world and assisting them with high quality code. A team of handpicked developers that are highly efficient, have a progressive mentality and an immense passion for programming.'
            }
          />
          <meta property="og:type" content="business.business" />
          <meta property="og:title" content={site.siteMetadata.title} />
          <meta property="og:url" content="/" />
          <meta property="og:image" content={favicon} />
          <meta name="google-site-verification" content="TAZyvCwfNRod7T0FvgzEaEznjHHjLWds9xo3qjgcoOQ" />
        </Helmet>
        {this.props.location.pathname.includes('/hire-a-dev') || this.props.location.pathname.startsWith('/cv') ? (
          children
        ) : (
          <>
            <div style={{ minHeight: '100vh', backgroundColor: '#052d2961' }}>
              <Header
                name={site.siteMetadata.name}
                title={site.siteMetadata.title}
                date={site.siteMetadata.date}
                navbar={this.state.navbar}
                toggleNavbar={this.toggleNavbar}
                closeNavbar={this.closeNavbar}
                pathName={this.props.location.pathname}
              />
              <Swipeable onSwipedLeft={this.swipeLeft} onSwipedRight={this.swipeRight}>
                <Transition location={location}>
                  <div id="slide" style={{ width: '100%' }}>
                    {false && this.props.path === '/' && (
                      <div
                        style={{
                          width: '100%',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          flexDirection: 'column',
                          maxHeight: '90%',
                          overflowY: 'scroll',
                        }}
                      >
                        <div className="homeWrapper">
                          <div className="home">
                            <StaticImage src="../images/full-logo-2.png" alt="Full Logo" height="60%" />
                            <h3 className="sub-title">Where the code ethic prospers!</h3>
                          </div>
                        </div>

                        {/* <div>
                          <h2 className="title">Our Team</h2>
                          <Team />
                        </div> */}
                      </div>
                    )}
                    {children}
                  </div>
                </Transition>
              </Swipeable>
            </div>
            <Footer fluidPage={fluidPage} />
          </>
        )}
      </div>
    );
  }
}

TemplateWrapper.propTypes = {
  children: PropTypes.node,
  data: PropTypes.object,
};

export default props => (
  <StaticQuery
    query={graphql`
      query IndexQuery {
        site {
          siteMetadata {
            name
            title
            date
          }
        }
      }
    `}
    render={data => <TemplateWrapper site={data.site} {...props} />}
  />
);
